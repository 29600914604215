$orange: #FF9300;
$darker-orange: #E27600;
$lighter-orange: #FFA300;
$deepBlue: #05315E;
$blue: #4990E2;
$turquoise: #50E3C2;
$gray: #ddd; 
$black: #333;

/* ------------- */
/* -- General -- */
/* ------------- */

html,body { height:100%; background-color: $gray-white;}

body {
	overflow-x: hidden;
	line-height: 1.60;
}

.site-wrapper {
	min-height: 100%;
    position: relative;
}

.site-content { padding-bottom:140px; }

a { 
    color: $link;
    &:active {
        outline: !important;
    }
}
ul { margin-bottom: 10px; }
hr { border-color:#333; }

input::-moz-focus-inner { border: 0 !important; }
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

.state-icon {
    background-size:500px 500px;
    background-repeat: no-repeat;
    width:340px;
    height:300px;
    background-position: center -50px;
    position: relative;
}
span.state-text {
    font-size:18px;
    height:29px;
    padding:2px 10px;
    color:#FFF;
    text-transform: uppercase;
    background-color:#9c9b9b; 
    border-radius: 10px;
    position: absolute;
    left:43%;
    top:20%;
}

/* --------------------- */
/* -- Header -- */
/* --------------------- */

header { 
    background-color:#444d52;
    position:relative; 
    overflow:hidden;

    #SiteNavigation {
        #logo { display:block; height:auto; width:252px; padding:0; }
        .navbar-default {
            background-color:transparent;

            ul.navbar-nav { 
                float:right;
                margin-top:15px;

                li a {
                    font: normal 300 18px/33px $font-family-sans-serif;
                    color: #FFF;
                    &:hover {
                        background-color:#eee;
                        color: #333 !important;
                    }
                    &:active {
                        color: #333 !important;
                    }
                    &:focus {
                        color: #333 !important;
                    }
                }
                li.active a {
                    color:#333;
                }
            }

            .navbar-toggle {
                margin-top:20px;
                margin-right:0;
                border-color:#FFF;

                .icon-bar {
                    background-color:#FFF;
                }
            }
        }
    }
}

/* ------------- */
/* -- Content -- */
/* ------------- */

.horizontal-top {
    background-color:$light-gray;

    a { 
        outline: none; 
        &:focus {
            outline: none; 
        }   
    }
    img {
        margin:20px 0;
    }
}

.vertical-side {
    background-color:#FFF;

    img {
        margin: 10px 0 15px 0;
    }
}

article { padding-right:25px; }
.breadcrumb { background-color:transparent; margin-bottom:0; padding-bottom:0; }

.article-header { padding-bottom:15px; border-bottom:5px solid $green-border;}
.article-body-header { font-weight:700; font-size:18px; }

.custom-box { 
    border:1px solid $light-gray; 
    background-color:#FFF; 

    .inner { padding:15px; }
    .custom-button-container { margin:35px 30px 0 0;}
    .custom-button-container p { color:#fd835b; }
    .ratings i { color:#fa9742; }
}

.custom-box.cba {
    background-size: 26px 20px; 
    background-repeat: no-repeat; 
    background-position: 99% 1%;
}

.btn-custom {
    background-color: #fd835b;
    border-bottom: 2px solid #9d4040;
    color: #FFF;
    width:100%;
}

.btn-pill { border-radius:15px; border-color:#d4d4d4; border-width:1px; color:#333;}

.category { display:block; width:48%; float:left; margin-bottom:20px;}
.category-thumb { width:50%; margin-right:2%; }
.category-text { width:48%; }
.category-label a {
    font-size:14px;
    font-weight:bold;
    line-height:1em;
    display:inline-block;
    &:hover {
        text-decoration: underline;
    }
}
.article-list { 
    list-style-type: none; 
    margin:0; 
    padding:0;

    li {

    }

    a {
        font-style: italic;
        line-height:1.2em; 
        display:inline-block;
    }
}

img.ad { max-width: 100%; }

.resource-list { list-style-type: none; }
.resource-list li {
    padding:5px 0 5px 30px;
    background-image: url('/img/pdf_icon.png'); 
    background-size:20px 20px; 
    background-repeat: no-repeat;
}

.blue-check-list { text-align:left; margin-left:10px;}
.blue-check-list li {
    background: url('/img/svg/anim-checkbox.svg') no-repeat 0 0;
    background-size:20px 20px;
    padding: 0 0 12px 30px;
    font-size:16px;
    line-height: normal;
    list-style-type: none;
}

.mini-side-nav { list-style-type: none; margin:0; padding:0;}
.mini-side-nav li { padding:15px 0; border-bottom:2px solid #f9f9f9; font-size:18px; }

/* --------------------- */
/* -- Footer -- */
/* --------------------- */
footer {
    bottom: 0;
    height: 120px;
    left: 0;
    position: absolute;
    width: 100%;
    background-color:#444d52;
    color:#FFF;

    .inner {
        padding:25px 0;

        a { 
            color:#FFF; 

            &:hover { color:#FFF; }
        }
    }
}

/* ------------------- */
/* -- Helper Classes -- */
/* ------------------- */
.mt-0 { margin-top:0;}
.mt-5 { margin-top:5px;}
.mt-10 { margin-top:10px;}
.mt-15 { margin-top:15px;}
.mt-20 { margin-top:20px;}
.mb-0 { margin-bottom:0;}
.mb-5 { margin-bottom:5px;}
.mb-10 { margin-bottom:10px;}
.mb-15 { margin-bottom:15px;}
.mb-20 { margin-bottom:20px;}
.mr-0 { margin-right:0;}
.mr-5 { margin-right:5px;}
.mr-10 { margin-right:10px;}
.mr-15 { margin-right:15px;}
.mr-20 { margin-right:20px;}
.ml-0 { margin-left:0;}
.ml-5 { margin-left:5px;}
.ml-10 { margin-left:10px;}
.ml-15 { margin-left:15px;}
.ml-20 { margin-left:20px;}

.wd-25 { width:25%; }
.wd-30 { width:30%; }
.wd-50 { width:50%; }
.wd-70 { width:70%; }
.wd-75 { width:75%; }

.big { font-size:110%;}

.inner-left-10 { padding-left:10px; }

.text-hidden { display:none; }

/* ------------------- */
/* -- Form Styles -- */
/* ------------------- */
.form-group { overflow: hidden;}
.has-error .form-control.no-error { border-color:#ccc; }

.cc-visa { display:inline-block; width:58px; height:36px; background: transparent url('/img/cc_logos/visa_logo_big.png') 0 0 no-repeat; background-size: 58px 36px;}
.cc-mastercard { display:inline-block; width:58px; height:36px; background: transparent url('/img/cc_logos/mastercard_logo_big.png') 0 0 no-repeat; background-size: 58px 36px; }
.cc-discover { display:inline-block; width:58px; height:36px; background: transparent url('/img/cc_logos/discover_logo_big.png') 0 0 no-repeat; background-size: 58px 36px;}

//Ad Fonts
.ad-header {
    font-size:28px;
    color: #23527c;
    text-align: center;
}

.ad-subtitle {
    font-size:16px;
    color:#5397e1;
}

.logo-header {
    font-size:28px;
    color: #23527c;
    text-align: center;
}

.btn-orange {
    background-color: $orange; /* Old browsers */
    text-shadow: 1px 1px 1px black;
    color: #fff;
    border: 1px solid $darker-orange;
    min-height: 50px;
    font-size:24px;

    &:hover {
        color: #fff;
        background-color: $lighter-orange;
    }
}

.check-ul {
    padding: 0px;
    li{
       list-style: none;
       font-size:16px;
    }
    li:before{
       content: '';
       display: inline-block;
       height: 33px;
       width: 36px;
       background-image: url('/img/check.png');
       background-size:contain;
       background-repeat:no-repeat;
       top:10px;
       position:relative;
    }
}

/* ------------------- */
/* -- Media Queries -- */
/* ------------------- */

/*==========  Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    .ad-vertical img {
        margin: 10px 0 40px 0;
    }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
    .category { width:100%; }
}

/* Medium Only */
@media (min-width: 992px) and (max-width: 1199px) {
    .state-icon {
        background-size:400px 400px;
        background-repeat: no-repeat;
        width:275px;
        height:250px;
        background-position: center -25px;
    }
}

@media only screen and (max-width: 768px) {
    .ad-header {
        font-size: 20px;
    }
    .logo-header {
        font-size: 16px; 
    }
    .ad-subtitle { font-size:11px;}
    .selectize-lg {
        margin-bottom:15px;
    }
    .check-ul {
        li{
           font-size:14px;
        }
    }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
    .category { width:100%; }	
    .category-thumb { width:100%; margin-right:0; }
    .category-text { width:100%; }
    header #SiteNavigation .navbar-default ul.navbar-nav { float:none; }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
	header .container { padding-left:5px; }
    .resource-list { margin:0; padding:0; }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 320px) {

}

@media print {
    a {
        display:none;
    }
}