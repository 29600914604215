
// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;
$blue-border: #3e8fe9;
$orange-border: #f7835a;
$green-border: #28d07d;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Typography
$font-family-sans-serif: "Open Sans", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #333;
$heading-color: #4d4d4d;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// Links
$link: #003d7a;

// Background Colors
$gray-white: #f9f9f9;
$light-gray: #ededed;